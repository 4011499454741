<script setup></script>

<template>
  <div class="foot_basediameter">
    <div class="footer">
      <!--第一部分-->
      <div class="bottom_row_one">
        <div class="botton_row_one_container">
          <!--左-->
          <div class="one-left">
            <span>使用方法</span
            ><!--使用指南-->
            <div class="znan">使用指南</div>
          </div>

          <!--中-->
          <div class="one-center">
            <div class="one-center-once">
              <span>与公司信息变更相关：</span>
              <span><u>admin@filterap.com</u></span>
              <span>&nbsp;(文件大小不超过15MB)</span>
            </div>

            <div class="one-center-two">
              <span>电话：</span>
              <span><u>0318-7561509</u></span>
              <span>服务时间：</span>
              <span><u>8:00～18:00</u></span>
            </div>

            <div class="one-center-three">
              <span>投诉与建议：</span>
              <span><u>单击此处</u></span>
            </div>
          </div>

          <!--右-->
          <div class="one-right">
            <div class="right-container">
              <div class="right-img">
                <img src="../../assets/index/short_duration1.webp" alt="" />
                <div class="iner">
                  <span>时间</span>
                </div>
              </div>
              <div class="right-img">
                <img src="../../assets/index/low_price2.webp" alt="" />
                <div class="iner">
                  <span>低价位</span>
                </div>
              </div>
              <div class="right-img">
                <img src="../../assets/index/high_quality3.webp" alt="" />
                <div class="iner">
                  <span>高质量</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--第二部分-->
      <div class="bottom_row_two">
        <div class="botton_row_two_container">
          <!--上-->
          <div class="container_top">
            <span>查询下载</span>
            <span>|</span>
            <span>财务信息下载</span>
            <span>|</span>
            <span>账户申请表下载</span>
            <span>|</span>
            <span>公司信息</span>
          </div>

          <!--中-->
          <div class="container_center">
            <span>使用规则</span>
            <span>|</span>
            <span>客户信息保护</span>
            <span>|</span>
            <span>网站地图</span>
            <span>|</span>
            <span>公司概要</span>
            <span>|</span>
            <span>工作信息</span>
            <span>|</span>
            <span>公司概要</span>
            <span>|</span>
            <span>行为准则</span>
          </div>

          <!--下-->
          <div class="container_bottom">
            <span>相关网站：<span>安平县润嵘金属丝网制品</span></span>
          </div>
        </div>
      </div>

      <!--第三部分-->
      <div class="bottom_row_three">
        <div class="bottom_row_three_container">
          <div class="container_three">
            <span>冀ICP备17009166号-1</span>
            <span>|</span>
            <span>关于我们</span>
            <span>|</span>
            <span>联系服务</span>
            <span>|</span>
            <span>法律公告</span>
            <span>|</span>
            <span>知识产权</span>
            <span>|</span>
            <span>诚邀人才</span>
          </div>

          <div class="center_three">
            <span>电子商务营业执照</span>
            <span>|</span>
            <span>隐私政策</span>
            <span>|</span>
            <span>Copyright © 2021 安平县润嵘金属丝网制品有限公司</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
